// extracted by mini-css-extract-plugin
export var buttonContainer = "homePage-module--buttonContainer--62a9e";
export var buttonMd = "homePage-module--button-md--cef74";
export var carousel = "homePage-module--carousel--f854d";
export var grey = "homePage-module--grey--9c703";
export var greyFont = "homePage-module--greyFont--c0062";
export var headerContainer = "homePage-module--headerContainer--8f216";
export var homeAnnoucement = "homePage-module--homeAnnoucement--e91b6";
export var homeContainer = "homePage-module--homeContainer--ddef0";
export var rightBox = "homePage-module--rightBox--c6fe2";
export var spanBold = "homePage-module--spanBold--59b9a";
export var yellow = "homePage-module--yellow--cb971";