import React from 'react';
import * as homePageStyles from './homePage.module.scss';
import CarouselHome from '../CarouselHome/carouselHome';

const HomePage = () => {
  return (
    <>
      <div className={`container-fluid ${homePageStyles.homeAnnoucement}`}>
        {/*        <div className={`row`}>
          <h3>
            Betriebsferien vom 27.12.2022 bis 30.12.2022 - Gerne bedienen wir
            Sie wieder ab dem 3.1.2023
          </h3>
        </div> */}
      </div>
      <div className={`${homePageStyles.homeContainer}`}>
        <div className={`col-md-12 col-lg-7`}>
          <CarouselHome />
        </div>
        <div className={`col-md-12 col-lg-4 ${homePageStyles.rightBox}`}>
          <h1>
            Willkommen bei <br />
            <span className='yellow'>Bowman Automobile AG</span>
          </h1>
          <div className={`mt-2 mb-2`}>
            <p>
              Entdecken Sie jetzt unsere Occasionen und kommen Sie in Schinznach
              vorbei für eine unverbindliche Probefahrt. Zusätzlich können Sie
              hier direkt Ihren nächsten Termin für einen Räderwechsel.
            </p>
            <div className={`${homePageStyles.buttonContainer}`}>
              <a href='/ocassionen'>
                <button className='button-md align-self-center'>
                  Fahrzeug Angebote
                </button>
              </a>
              <a href='https://bowman.sopl.ch/' target='_blank'>
                <button className='button-md align-self-center'>
                  Termin vereinbaren
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
